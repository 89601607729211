* {
  margin: 0;
  padding: 0;
  font-size: 20px;

  -webkit-font-smoothing: antialiased;
}
/*_:default:not(:root:root), :root * {

  background-color:black; 

}*/
.qr-reader {
  max-width: 300px;
}
.react-datetime-picker {
  z-index: 2;
}
.App {
  display: none;
  width: 100vw;
  height: 100vh;
  text-align: center;
}

.Chats {
  width: 100vw;
  height: 100vh;
  text-align: center;
  transition: transform ease-out 1s;
}
.chats_toleft {
  left: 0px;
  transition: ease-out 0.3s;
  transform: translate(-100%, 0%);
}
.chats_non {
  visibility: hidden;
}

.Planner {
  display: flex;
  position: absolute;
  width: 100vw;
  height: 100vh;
  text-align: center;
  transition: transform ease-out 1s;
}

.Events {
  display: none;
  width: 100vw;
  height: 100vh;
  text-align: center;
  transition: transform ease-out 1s;
}

.Map {
  display: none;
  width: 100vw;
  height: 100vh;
  text-align: center;
  transition: transform ease-out 1s;
}

/*.fade-enter {
  opacity: 1;
}
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}
.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}
`;*/
.buttonboxer {
  height: 56px;
  width: 56px;
}

.showSC {
  display: flex;
  position: fixed;
}
.hideSC {
  display: none;
  position: fixed;
  bottom: -50px;
}
.showLoginPage {
  display: flex;
}
.hideLoginPage {
  display: none;
}

.queryingWaitLoading {
  display: flex;
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background-color: rgba(255, 255, 255, 0.53);
  color: #999;
  z-index: 10000;
}
.showEvents {
  display: flex;
  position: absolute;
  right: 0px;
  top: 56px;
  justify-content: flex-end;
  transform: translateX(0%);
  transition: 0.3s ease-in;
  width: 60%;
  background-color: rgba(51, 51, 51, 0.408);
  z-index: 1000;
}
.showEventsfixed {
  display: flex;
  position: fixed;
  right: 0px;
  top: 0px;
  transform: translateX(100%);
  transition: 0.3s ease-in;
  width: 60vw;
  background-color: rgba(51, 51, 51, 0.408);
  z-index: 1000;
}
.hideEvents {
  display: flex;
  position: fixed;
  right: 0px;
  top: 56px;
  justify-content: flex-end;
  width: 60%;
  background-color: rgba(51, 51, 51, 0.408);
  z-index: 1000;
  transform: translateX(100%);
  transition: 0.3s ease-in;
}
@media (max-width: 500px) {
  .showEvents {
    display: flex;
    position: absolute;
    right: 0px;
    top: 0px;
    transform: translateX(0%);
    transition: 0.3s ease-in;
    background-color: rgba(51, 51, 51, 0.408);
    z-index: 1000;
    width: 100vw;
  }
}
.showSWmap {
  display: flex;
  position: absolute;
  right: 0px;
  top: 0px;
  transform: translateX(0%);
  transition: 0.3s ease-in;
  background-color: rgba(51, 51, 51, 0.408);
  z-index: 1000;
  width: 100%;
}
.showSWmap {
  display: flex;
  position: absolute;
  right: 0px;
  top: 0px;
  transform: translateX(0%);
  transition: 0.3s ease-in;
  background-color: rgba(51, 51, 51, 0.408);
  z-index: 2006;
}
.hideSWmap {
  display: flex;
  position: fixed;
  height: 100%;
  width: 100%;
  transform: translateX(100%);
  z-index: 2000;
  transition: transform 0.3s ease-out;
}
.randomheader {
  display: flex;
  position: fixed;
  align-items: center;
  z-index: 0;
  border: none;
  text-indent: 88px;
  padding-left: 2%;
  height: 56px;
  width: 100%;
  background-color: #00000078;
  color: rgba(255, 255, 255, 0.644);
  font-size: 26px;
}
.randomheader1 {
  display: flex;
  position: fixed;
  align-items: center;
  z-index: 0;
  border: none;
  text-indent: 88px;
  padding-left: 2%;
  height: 56px;
  width: 100%;
  background-color: #00000078;
  color: rgba(255, 255, 255, 0.644);
  font-size: 26px;
}

.showchats {
  display: flex;
  position: fixed;
  width: 100%;
  top: 0px;
  bottom: 0px;
  transform: translateX(0%);
  transition: transform 0.3s ease-out;
}
.hidechats {
  display: flex;
  position: fixed;
  width: 100%;
  top: 0px;
  bottom: 0px;
  transform: translateX(-120%);
  transition: transform 0.3s ease-out;
}
.hideplanner {
  display: flex;
  position: fixed;
  top: 100%;
}
.showplanner {
  display: flex;
  position: absolute;
  left: 0px;
  top: 0px;
  transform: translateX(0%);
  transition: 0.3s ease-in;
  width: 60%;
  background-color: rgba(51, 51, 51, 0.408);
  z-index: 1;
}
@media (max-width: 1210px) {
  .showplanner {
    display: flex;
    position: absolute;
    left: 0px;
    top: 0px;
    transform: translateX(0%);
    transition: 0.3s ease-in;
    width: 100%;
    background-color: rgba(51, 51, 51, 0.408);
    z-index: 1;
  }
}

.showPostersStore {
  display: flex;
  position: absolute;
  left: 0px;
  top: 0px;
  transform: translateX(0%);
  transition: 0.3s ease-in;
  width: 60%;
  background-color: rgb(255, 255, 255);
  z-index: 9999;
}

.hidePostersStore {
  display: flex;
  position: fixed;
  top: 100%;
  transform: translateX(-100%);
  transition: 0.3s ease-in;
}

.listversion {
  display: flex;
  position: fixed;
  bottom: 36px;
  width: 56px;
  height: 56px;
  left: 10px;
  z-index: 1000;
  background-color: rgba(51, 51, 51, 0.85);
  border: 1px solid white;
}
.showingwindow {
  display: flex;
  position: fixed;
  bottom: 36px;
  width: 80%;
  height: 80%;
  top: 50%;
  z-index: 1000;
  color: #333;
  background-color: rgba(255, 255, 255, 0.85);
  border: 1px solid white;
  transform: translate(0%, 0%);
  transition: 0.3s ease-in;
}
.notshowingwindow {
  display: flex;
  position: fixed;
  bottom: 36px;
  width: 80%;
  height: 80%;
  top: 50%;
  z-index: 1000;
  background-color: rgba(51, 51, 51, 0.85);
  border: 1px solid white;
  transform: translate(0%, 100%);
  transition: 0.3s ease-in;
}
